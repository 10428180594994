/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
Note: any .css or .scss files included in the 'styles' directory
will be correctly compiled during `gulp serve` and `gulp`
 */

html,
body {
  font-family: "Roboto", "Helvetica", sans-serif;
  margin: 0;
  padding: 0;
}

/*.mdl-demo .jm-main {
  min-height: 100%;
}*/

.mdl-demo .mdl-color--primary {
  background-color: #6a98ae !important;
}

.mdl-demo .mdl-color--primary-dark {
  background-color: #557a8c !important;
}

.mdl-demo .mdl-layout__header {
}

.mdl-demo .mdl-layout__header-row h1 {
  padding-left: 40px;
}
.mdl-demo .mdl-layout__header-row {
  padding: 0;
}

.mdl-layout__header-row.scrollable {
  overflow-x: scroll;
}

/*.mdl-demo .mdl-layout.is-small-screen .mdl-layout__header-row h3 {
  font-size: inherit;
}*/

.mdl-demo .mdl-layout.is-small-screen .mdl-layout__header-row h1 {
  padding-left: 16px;
  font-size: 14px;
}

.mdl-demo .mdl-layout__header-row h1 .logo {
  margin-right: 12px;
}

.mdl-demo .mdl-layout__tab-bar-button {
  display: none;
}

.mdl-demo .mdl-layout.is-small-screen .mdl-layout__tab-bar .mdl-button {
  display: none;
}

.mdl-demo .mdl-layout:not(.is-small-screen) .mdl-layout__tab-bar,
.mdl-demo .mdl-layout:not(.is-small-screen) .mdl-layout__tab-bar-container {
  overflow: visible;
}
.mdl-demo .mdl-layout__tab-bar-container {
  height: 64px;
}
.mdl-demo .mdl-layout__tab-bar {
  padding: 0;
  padding-left: 16px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
.mdl-demo .mdl-layout__tab-bar .mdl-layout__tab {
  height: 64px;
  line-height: 64px;
}
.mdl-demo .mdl-layout__tab-bar .mdl-layout__tab.is-active::after {
  background-color: white;
  height: 4px;
}

.mdl-demo .mdl-tabs .mdl-tabs__tab {
  color: rgba(255, 255, 255, 0.6);
}

.mdl-demo .mdl-tabs .mdl-tabs__tab.is-active {
  color: rgba(255, 255, 255, 0.95);
  border-bottom: 4px solid white;
}

.mdl-demo main > .mdl-layout__tab-panel {
  padding: 8px;
  padding-top: 48px;
}
.mdl-demo .mdl-card {
  height: auto;
  display: flex;
  flex-direction: column;
  min-height: 100px;
}
.mdl-demo .mdl-card > * {
  height: auto;
}

.mdl-demo .mdl-card .hint {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  padding: 2px 5px;
}

.mdl-demo .mdl-card a.titlelink {
  color: inherit;
  /*text-decoration: none;*/
  font-weight: 300;
}

.mdl-demo .mdl-card a.titlelink:hover {
  text-decoration: underline;
}

.mdl-demo.mdl-demo .mdl-card__supporting-text.topoffset {
  padding-top: 28px;
}

.mdl-demo .mdl-card .mdl-card__supporting-text,
.mdl-demo .mdl-card .mdl-card__supporting-text a {
  margin: 16px auto 4px auto;
  flex-grow: 1;
  padding: 0;
  color: inherit !important;
  width: calc(100% - 32px);
  /*width: 100%;*/
}

.mdl-demo .mdl-card .mdl-card__supporting-text .transport,
.mdl-demo .mdl-card .mdl-card__supporting-text .accommodation-rank {
  font-size: 16px;
  font-weight: 500;
  margin-right: 8px;
}

.mdl-demo .mdl-card .mdl-card__supporting-text a {
  font-weight: normal;
}

.mdl-demo .mdl-card .mdl-card__supporting-text a > h5 {
  text-decoration: underline;
}

.mdl-demo.mdl-demo .mdl-card__supporting-text h4,
.mdl-demo.mdl-demo .mdl-card__supporting-text h5 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: 500;
  display: inline-block;
}

.mdl-demo .mdl-card .mdl-card__supporting-text .addr {
  margin: 0 0 10px 0;
  font-size: 12px;
}

.mdl-demo .mdl-card .mdl-card__supporting-text .addr .fa {
  font-weight: 500;
  margin-right: 4px;
}

.mdl-demo .mdl-card .mdl-card__supporting-text p {
  line-height: 20px;
}

.mdl-demo .mdl-card .mdl-card__supporting-text p:last-child {
  margin-bottom: 0px;
}

.mdl-demo .mdl-card .mdl-card__supporting-text .boxednote {
  padding: 12px 18px;
  background-color: #eee;
  margin-bottom: 8px;
}
.mdl-demo .mdl-card .mdl-card__supporting-text .boxednote p {
  font-weight: 500;
  line-height: 18px;
}

.mdl-demo .mdl-card__actions {
  margin: 0;
  padding: 0px 20px 8px 20px;
  color: inherit;
  text-align: right;
}
.mdl-demo .mdl-card__actions a {
  color: #00bcd4;
  margin: 0;
}
.mdl-demo .mdl-card__actions a:hover,
.mdl-demo .mdl-card__actions a:active {
  color: inherit;
  background-color: transparent;
}
.mdl-demo .mdl-card__supporting-text + .mdl-card__actions {
  /*border-top: 1px solid rgba(0, 0, 0, 0.12);*/
}
.mdl-demo #add {
  position: absolute;
  right: 40px;
  top: 36px;
  z-index: 999;
}

.mdl-demo .mdl-card__supporting-text p a,
.mdl-demo .mdl-card__supporting-text td a {
  color: inherit;
  font-weight: 300;
}

.mdl-demo .mdl-layout__content {
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.mdl-demo .mdl-layout__content section:not(:last-of-type) {
  position: relative;
  /*margin-bottom: 20px;*/
}
.mdl-demo .mdl-layout__content section.section-note:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mdl-demo .jm-source-link {
  height: 24px;
  width: 24px;
  display: inline-block;
}

.mdl-demo .mdl-card {
  border-radius: 0px;
}

.mdl-demo .jm-shadow {
  max-width: 728px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  .mdl-demo .mdl-layout__content section:not(:last-of-type) {
    /*margin-bottom: 12px;*/
  }
  .mdl-demo .mdl-layout__content section {
    box-shadow: none;
  }
}

.mdl-demo section.section--center {
  /*max-width: 860px;*/
  max-width: 728px;
}
.mdl-demo #features section.section--center {
  max-width: 620px;
}

.mdl-demo section.section--center.header {
}

.mdl-demo section.section--center.header h4 {
  margin: 12px 0px 12px;
}

.mdl-demo section > header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mdl-demo section > .section__play-btn {
  min-height: 200px;
}
.mdl-demo section > header > .material-icons {
  font-size: 3rem;
}
.mdl-demo section > button {
  position: absolute;
  z-index: 99;
  top: 8px;
  right: 8px;
}
.mdl-demo section .section__circle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 1;
}
.mdl-demo section .section__text {
  flex-grow: 1;
  flex-shrink: 0;
  padding-top: 8px;
}
.mdl-demo section .section__text h5 {
  font-size: inherit;
  margin: 0;
  margin-bottom: 0.5em;
}
.mdl-demo section .section__text a {
  text-decoration: none;
}
.mdl-demo
  section
  .section__circle-container
  > .section__circle-container__circle {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin: 8px 0;
}
.mdl-demo section.section--footer .section__circle--big {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin: 8px 32px;
}
.mdl-demo .is-small-screen section.section--footer .section__circle--big {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 8px 16px;
}
.mdl-demo section.section--footer {
  padding: 64px 0;
  margin: 0 -8px -8px -8px;
}
.mdl-demo section.section--center .section__text:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}
.mdl-demo .mdl-card .mdl-card__supporting-text > h3:first-child {
  margin-bottom: 24px;
}
.mdl-demo .mdl-layout__tab-panel:not(#overview) {
  background-color: white;
}
.mdl-demo #features section {
  margin-bottom: 72px;
}
.mdl-demo #features h4,
#features h5 {
  margin-bottom: 16px;
}
.mdl-demo .toc {
  border-left: 4px solid #c1eef4;
  margin: 24px;
  padding: 0;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
}
.mdl-demo .toc h4 {
  font-size: 0.9rem;
  margin-top: 0;
}
.mdl-demo .toc a {
  color: #4dd0e1;
  text-decoration: none;
  font-size: 16px;
  line-height: 28px;
  display: block;
}
.mdl-demo .mdl-menu__container {
  z-index: 99;
}

.mdl-demo .mdl-navigation {
  margin: 0 auto;
}

.mdl-demo .jm-footer {
  margin-top: 24px;
}

.mdl-demo .jm-search-box {
  color: white;
  margin-right: 40px;
}

.mdl-demo .jm-search-box .mdl-textfield__input {
  border-bottom: 1px solid rgba(206, 206, 206, 0.64);
  /*background-color: rgba(255, 255, 255, 0.55);
  color: #333;
  padding: 3px;*/
}

@media (max-width: 900px) {
  .mdl-demo .jm-search-box {
    color: white;
    margin-right: 16px;
  }
  .mdl-demo h3.main-header {
    padding-left: 16px;
  }
}

.mdl-demo .jm-search-dropdown {
  position: absolute;
  top: 49px;
  right: 0;
  background: white;
  color: black;
  z-index: 10;
  min-width: 300px;
  text-align: left;
}

.mdl-demo .jm-search-dropdown a {
  color: black;
  text-decoration: none;
  padding: 6px 18px;
  width: 100%;
  display: block;
}

.mdl-demo .jm-search-dropdown ul {
  margin: 0 !important;
  padding: 0 !important;
}
.mdl-demo .jm-search-dropdown li {
  margin: 0 !important;
  padding: 0 !important;
}

.mdl-demo .jm-search-dropdown li:hover {
  background-color: #ccc;
}

.mdl-demo .jm-note-img-container {
  position: relative;
  /*padding: 8px !important;*/
  background: white;
}

.mdl-demo .jm-noteimg {
  height: 100%;
  width: auto;
  overflow: hidden;
  position: absolute;
  left: 0;
}

.mdl-demo .jm-noteimg:hover {
  opacity: 0.8;
}

@media (max-width: 900px) {
  .mdl-demo .jm-noteimg {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: absolute;
    left: 0;
  }
}

.mdl-demo .jm-search-dropdown ul {
  padding-left: 20px;
}

.mdl-demo .jm-search-dropdown ul li {
  list-style: none;
  margin: 10px 0;
}

@media (max-width: 900px) {
  .mdl-demo .jm-search-dropdown {
    position: fixed;
    height: 100%;
    width: 100%;
  }
}

.mdl-demo .jm-section-name {
  padding: 4px 9px;
  margin: 4px 2px;
  text-decoration: none;
  color: #616161 !important;
}

.mdl-demo .jm-dst-path {
  margin-bottom: 20px;
}

.mdl-demo .jm-dst-path a {
  font-size: 18px;
  font-weight: 600;
  /*text-decoration: none;*/
  color: inherit;
  line-height: 28px;
}

section.jm-googlead {
  /*margin-bottom: -24px !important;*/
  display: block;
  /*min-height: 90px;*/
}

@media (max-width: 900px) {
  section.jm-googlead {
    box-shadow: none !important;
  }
}

.mdl-demo .jm-dst-path .container {
  margin: 0 20px;
}

.mdl-demo .jm-overlay-map {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.3);
}

.mdl-demo .jm-overlay-map .jm-container {
  margin: 30px;
  padding: 10px;
  background-color: white;
  /*height: 620px;*/
  height: calc(100% - 60px);
  /*height: 600px;*/
  max-height: 600px;
  /*position: relative;*/
}

.mdl-demo .jm-overlay-map .jm-container .jm-map-left {
  width: 300px;
  vertical-align: top;
}

.mdl-demo .jm-overlay-map .jm-container .jm-map-right {
  height: 100%;
  width: calc(100% - 304px);
  /*width: 100%;*/
  display: inline-block;
}

.mdl-demo .jm-overlay-map .jm-container .jm-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border: none;
  margin: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  z-index: 100;
}

.jm-close-text-mobile {
  display: none;
}

.jm-close-text-desktop {
  display: block;
  line-height: 21px;
}

.jm-dst-photo {
  /*width: 33% !important;*/
  vertical-align: middle;
  border-radius: 0;
}

.jm-dst-photo .author {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 10px;
  padding: 0px 5px;
  background: rgba(0, 0, 0, 0.4);
  color: #ccc;
}

.jm-dst-photo .author a {
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

@media (min-width: 479px) {
  .jm-dst-photo {
    background-color: #000;
  }
  .jm-dst-photo img {
    width: 100% !important;
    height: auto !important;
    margin: auto;
  }
}

@media (max-width: 479px) {
  .jm-dst-photo {
    max-height: 250px;
  }
}

.jm-groups-titles ul {
  margin: 0;
  padding: 0;
}

.jm-groups-titles ul li {
  display: inline-block;
  margin: 2px 2px;
}

@media (min-width: 479px) {
  .jm-groups-titles {
    position: fixed !important;
  }
  .jm-groups-titles ul li {
    display: block;
  }
}

@media (max-width: 900px) {
  .mdl-demo .jm-overlay-map .jm-container {
    margin: 10px;
    padding: 5px;
    height: calc(100% - 30px);
  }

  .mdl-demo .jm-overlay-map .jm-container .jm-map-left {
    width: 100%;
    vertical-align: bottom;
    height: 100px;
  }

  .mdl-demo .jm-overlay-map .jm-container .jm-map-right {
    margin-top: 5px;
    height: calc(100% - 150px);
    width: 100%;
    display: inline-block;
  }

  .mdl-demo .jm-overlay-map .jm-container .jm-close {
    display: block;
    width: 100%;
    height: 30px;
    position: relative;
    margin: -5px 10px 0 10px;
    vertical-align: top;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .jm-close-text-mobile {
    display: block;
    font-size: 16px;
  }

  .jm-close-text-desktop {
    display: none;
  }
}

.mdl-demo .jm-home-search-input {
  display: block;
  margin: 0 auto;
  margin-top: 18px;
  padding: 6px 12px;
  font-size: 18px;
  min-width: 300px;
}

.mdl-demo .jm-search-box-home {
  text-align: center;
  margin: 0 auto;
  margin-top: 30%;
  position: relative;
}

.mdl-demo .jm-search-box-home .jm-search-dropdown {
  top: 120px;
  left: 17px;
  right: 17px;
}

@media (max-width: 900px) {
  .mdl-demo .jm-search-box-home .jm-search-dropdown {
    position: absolute;
    height: auto;
    width: auto;
  }
}

.mdl-demo .jm-top-destinations {
}

.mdl-demo .jm-top-destinations ul {
  padding-left: 0;
}

.mdl-demo .jm-top-destinations ul li {
  list-style: none;
  display: inline-block;
  margin: 4px 12px;
}

.mdl-demo .jm-top-destinations ul li b {
  margin-right: 4px;
  background-color: #902d30;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: white;
  /* padding: 0 5px; */
  /* line-height: 24px; */
  display: inline-block;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
}

.mdl-demo .jm-top-destinations ul li a {
  color: #6a98ae;
}

.mdl-demo .jm-header-section {
  margin: 0 auto;
}

.mdl-demo .jm-header-section h4 {
  width: 100%;
  margin-left: 0px !important;
  background-color: #ccc;
  padding-right: 10px;
  padding: 8px 16px;
  /*padding-left: 80px;*/
  /* color: white; */
}

.docs-layout .docs-layout-header.mdl-layout__header {
  /*height: 560px;*/
  /*max-height: 50%;
  flex-shrink: 0;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: none !important;
  align-items: flex-start;*/
  /*padding: 40px;*/
  flex-shrink: 0;
  position: relative;
  display: flex !important;
}
body.about .docs-layout.is-small-screen .docs-layout-header.mdl-layout__header {
  height: 280px;
}
body.about .docs-layout.is-small-screen .docs-layout-header.mdl-layout__header {
  background-size: auto 58px, cover;
  background-repeat: no-repeat, no-repeat;
  background-position: center center, center center;
}
/*.docs-layout-header .mdl-textfield {
  padding-top: 0;
}*/
/*.docs-layout-header > .mdl-layout__header-row {
  padding: 0px;
  align-items: flex-start;
  height: auto;
}*/
.docs-layout-title {
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5em;
  font-size: 1rem;
}
.docs-layout .docs-layout-title a {
  font-weight: inherit;
  color: white;
}
.docs-layout-header .mdl-textfield .mdl-button {
  right: 0;
}
.docs-layout-header .mdl-textfield .mdl-textfield__expandable-holder {
  margin-right: 32px;
}
.docs-layout-header .mdl-textfield label:after {
  background-color: rgba(255, 255, 255, 0.12);
}
.about .docs-layout-header.mdl-layout__header {
  background-color: #37474f;
  background: url("../images/logo.svg");
  background-size: auto 118px, cover;
  background-repeat: no-repeat, no-repeat;
  background-position: center center, center center;
}
@media (max-height: 600px) {
  .about .docs-layout-header.mdl-layout__header {
    background-size: auto 80px, cover;
  }
}
body:not(.about) .docs-layout .docs-layout-header.mdl-layout__header {
  background-repeat: no-repeat;
  background-position: center center;
}

.customize .docs-layout-header.mdl-layout__header {
  /*background-color: #1A237E;
  background-image: url('customize.png');
  background-size: auto 36px;*/
}
body:not(.about) .docs-layout .docs-layout-header.mdl-layout__header {
  box-sizing: border-box;
  /*height: 144px;*/
  /*background-position: 40px 32px;*/
}
body:not(.about) .docs-layout-title {
  display: none;
}
.docs-navigation__container {
  padding: 0 0 0 16px;
  /*overflow: hidden;
  position: absolute;
  height: 64px;
  width: 100%;
  bottom: 0;
  left: 0;*/
}
.docs-navigation {
  box-shadow: none !important;
  border: 0 !important;
  padding: 0 16px;
  width: 100%;
  height: 85px;
  flex-shrink: 0;
  padding-left: 24px;
  overflow-x: auto;
  overflow-y: hidden;
  user-select: none;
  margin-bottom: -16px;
}

.docs-layout.is-small-screen .navigation-row {
  position: relative;
  height: 64px;
}

.docs-layout.is-small-screen .docs-navigation {
  padding: 0;
  height: 64px;
}
.docs-layout.is-small-screen
  .docs-navigation
  .mdl-navigation__link
  .material-icons {
  display: none;
}
.docs-navigation::-webkit-scrollbar {
  display: none;
}
body:not(.about) .docs-layout.is-small-screen .docs-layout-header {
  background-image: none;
}
.docs-layout.mdl-layout.is-small-screen .docs-layout-header.mdl-layout__header {
  /*padding: 0;*/
  /*height: 64px;*/
}
.docs-layout.is-small-screen .docs-layout-header .docs-layout-title,
.docs-layout.is-small-screen .docs-layout-header .mdl-textfield {
  /*display: none;*/
}
.docs-layout.is-small-screen .docs-navigation__container {
  top: 0;
  bottom: auto;
  left: 48px;
  width: calc(100% - 2 * 48px);
  padding: 0 !important;
  position: relative;
}
.docs-layout .scrollindicator {
  position: absolute;
  top: 0;
  line-height: 64px;
  height: 64px;
  width: 48px;
  display: none;
  margin-right: 0;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.docs-layout .scrollindicator.disabled {
  opacity: 0.12;
  cursor: default;
}
.docs-layout .scrollindicator.scrollindicator--right {
  right: 0;
}
.docs-layout .scrollindicator.scrollindicator--left {
  left: 0;
}
.docs-layout.is-small-screen .scrollindicator.scrollindicator.scrollindicator {
  display: block;
}
.mdl-navigation__link--icon > span,
.mdl-navigation__link--icon > .material-icons {
  line-height: 64px;
  margin-right: 8px;
  line-height: inherit;
}
.docs-navigation .mdl-navigation__link {
  display: flex;
  flex-shrink: 0;
  user-select: inherit;
  height: 64px;
}
.docs-navigation .mdl-navigation__link:hover,
.docs-navigation .mdl-navigation__link.download:hover > span,
.docs-navigation .mdl-navigation__link.download:hover > .material-icons {
  background-color: inherit;
  opacity: 1 !important;
}
.docs-navigation .mdl-navigation__link:not(.download),
.docs-navigation .mdl-navigation__link.download > span,
.docs-navigation .mdl-navigation__link.download > .material-icons {
  opacity: 0.65;
}
.docs-navigation .mdl-navigation__link,
section.download {
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 64px;
  padding: 0 16px;
  color: white;
  box-sizing: border-box;
  border-bottom: 3px solid transparent;
}
.docs-layout.is-small-screen .docs-navigation .mdl-navigation__link,
.docs-layouy.is-small-screen section.download {
  padding: 0 12px;
}
.about
  .docs-layout:not(.is-small-screen)
  .mdl-navigation__link.download
  > .material-icons {
  display: none;
}
.about .docs-layout.is-small-screen .mdl-navigation__link.download > button,
body:not(.about) .mdl-navigation__link.download > button {
  display: none;
}
.docs-navigation .download button .material-icons {
  color: black;
  opacity: 0.54;
}
.about .docs-navigation .about,
.templates .docs-navigation .templates,
.showcase .docs-navigation .showcase,
.started .docs-navigation .started,
.styles .docs-navigation .styles,
.components .docs-navigation .components,
.faq .docs-navigation .faq,
.customize .docs-navigation .customize {
  opacity: 1 !important;
  border-bottom-color: white; /*#18FFFF;*/
}

.mdl-navigation__link.is-active {
  opacity: 1 !important;
  border-bottom-color: white; /*#18FFFF;*/
}

.mdl-layout__content.docs-layout-content {
  overflow: visible;
}
.docs-layout-content > .download {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.docs-layout-content > .download > a {
  font-weight: 500;
  text-transform: uppercase;
}
.docs-footer.mdl-mini-footer {
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 120px;
}
.docs-footer.mdl-mini-footer ul {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.docs-footer.mdl-mini-footer ul > li > a {
  margin: 0 8px;
  font-weight: 400;
  font-size: 12px;
}

.docs-footer .docs-link-list li {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.about-panel {
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
}
.about-panel--text {
  padding: 100px;
}
.about-panel--text p {
  width: 640px;
  margin: 0 auto;
  line-height: 2em;
}
.about-panel--text dl {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}
.about-panel--text dl dt {
  text-align: right;
  vertical-align: top;
  display: inline-table;
  margin-right: 24px;
  line-height: 2em;
}
.about-panel--text dl dd {
  text-align: left;
  line-height: 2em;
  vertical-align: top;
  width: 700px;
  margin: 0;
  margin-left: 24px;
}
.docs-layout.is-small-screen .about-panel {
  padding: 40px;
}
.docs-layout.is-small-screen .about-panel--text:not(:first-of-type) {
  display: none;
}
.docs-layout.is-small-screen .about-panel--text dl {
  flex-direction: column;
}
.docs-layout.is-small-screen .about-panel--text dd,
.docs-layout.is-small-screen .about-panel--text dt {
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
.about-panel--components,
.about-panel--styles,
.about-panel--customize,
.about-panel--templates {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 40px;
  color: white;
  text-transform: uppercase;
  font-size: 1.4rem;
  width: auto;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}
.docs-layout-content .about-panel--start > p {
  color: rgb(66, 66, 66);
  text-transform: uppercase;
  font-size: 1.6rem;
  margin-top: 20px;
  margin-bottom: 0px;
}

.templates .content {
  padding-left: 24px !important;
  max-width: 960px;
}
.templates .docs-layout-content .content {
  padding: 40px 0;
}
.templates .docs-layout .template {
  margin-left: -16px;
}

.showcase .content {
  padding-left: 24px !important;
  max-width: 960px;
}
.showcase .docs-layout-content .content {
  padding: 40px 0;
}
.showcase .docs-layout .template {
  margin-left: -16px;
}
.template {
  width: 100%;
  margin-bottom: 72px;
  align-items: flex-start;
}
.template > .template__meta {
  align-content: flex-start;
  padding-left: 24px;
}
.templates .docs-layout.is-small-screen .template > .template__meta {
  padding-left: 0;
}
.showcase .docs-layout.is-small-screen .template > .template__meta {
  padding-left: 0;
}
.template > .template__meta.template__meta > * {
  margin-bottom: 24px;
}
.template > .template__meta > *:last-child {
  margin-bottom: 0px;
}
.template > .template__meta a {
  color: inherit;
  margin-left: -8px !important;
}
.template > .template__preview {
  height: auto;
}
.docs-layout-content dd {
  font-size: 13px;
}
.docs-layout-content p {
  font-size: 13px;
  margin-bottom: 32px;
  max-width: 640px;

  /* Override */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.docs-text-styling ol li {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.docs-text-styling h1 {
  color: #c2185b;
  font-size: 2.5em;
  margin: 0.67em 0;
}

.docs-text-styling h2 {
  padding-top: 48px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 30px;
  color: #c2185b;
}

.docs-text-styling h1,
.docs-text-styling h2,
.docs-text-styling h3,
.docs-text-styling h4 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  text-transform: uppercase;
}
.docs-layout-content h2.mdl-card__title-text {
  padding-top: 0;
  margin-bottom: 0;
}

.docs-text-styling a {
  text-decoration: none;
}

.docs-layout-content .mdl-download {
  color: #000;
  font-weight: normal;
}

.docs-layout-title a {
  color: inherit;
  text-decoration: none;
}

.component-description {
  max-width: 720px;
  padding: 40px;
}
.component-description .mdl-button:first-of-type {
  margin-top: 8px;
}
.docs-footer.mdl-mini-footer .mdl-mini-footer--social-btn {
  background-color: transparent;
  margin: 0 16px;
  width: 24px;
  height: 24px;
}
.docs-footer.mdl-mini-footer .social-btn {
  display: block;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.social-btn__twitter {
  background-image: url("https://www.gstatic.com/images/icons/material/system/2x/post_twitter_white_24dp.png");
}
.social-btn__gplus {
  background-image: url("https://www.gstatic.com/images/icons/material/system/2x/post_gplus_white_24dp.png");
}
.subpageheader {
  margin-top: 60px;
  margin-bottom: 40px;
  display: flex;
  color: rgba(0, 0, 0, 0.54);
  align-items: center;
  flex-shrink: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
}
.about .subpageheader,
.components .subpageheader {
  display: none;
}
.started .subpageheader,
.customize .subpageheader,
.styles .subpageheader,
.faq .subpageheader,
.templates .subpageheader,
.showcase .subpageheader {
  width: 960px;
  margin: 40px auto;
}
/* Components Snippets */
.snippet-group {
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 84px;
}
.snippet-group .snippet-header {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.snippet-group .snippet-demos,
.snippet-group .snippet-captions {
  display: table-row;
}
.snippet-group .snippet-demo .snippet-demo-container {
  text-align: left;
  display: inline-block;
}
.snippet-group .snippet-captions {
  background-color: white;
  height: 48px;
}
.snippet-group .snippet-demo,
.snippet-group .snippet-demo-padding,
.snippet-group .snippet-caption,
.snippet-group .snippet-caption-padding {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

.snippet-group .snippet-caption {
  font-size: 13px;
  padding: 0px 40px;
  white-space: nowrap;
  text-align: center;
  position: relative;
}

.snippet-group .snippet-demo {
  padding: 0px 40px 40px 40px;
}
.snippet-group .snippet-demos .snippet-demo-padding {
  width: 50%;
}
_:-ms-input-placeholder,
:root .snippet-group .snippet-demo {
  width: 1px;
}
_:-ms-input-placeholder,
:root .snippet-group .snippet-demos .snippet-demo-padding {
  width: auto;
}
.snippet-group .snippet-code {
  position: relative;
  overflow: hidden;
}
.snippet-group .snippet-code pre {
  margin: 0;
  border-radius: 0;
  display: block;
  padding: 0;
  overflow: hidden;
}
.snippet-group .snippet-code code {
  padding: 8px 16px;
  position: relative;
  max-height: none;
  width: 100%;
  box-sizing: border-box;
}
.snippet-group .snippet-code pre[class*="language-"] > code[data-language] {
  max-height: none;
}
.snippet-group .snippet-code code:first-of-type {
  padding-top: 16px;
}
.snippet-group .snippet-code code:last-of-type {
  padding-bottom: 16px;
}
.snippet-group .snippet-code code:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.snippet-group .snippet-code code:hover:only-of-type {
  background-color: transparent;
}
.snippet-group .snippet-code code::before,
.snippet-group .snippet-code code::after {
  display: none;
}
.snippet-group .snippet-code code:hover::before {
  display: inline-block;
  content: "click to copy";
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.1);
  border-top-left-radius: 5px;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 3px 10px;
}
.snippet-group .snippet-code code:active::before {
  content: "";
}
.snippet-group .snippet-code code.copied::before {
  content: "copied";
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.6);
}
.snippet-group .snippet-code code.nosupport::before {
  content: "browser not supported :'(";
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.6);
}
.snippet-group .snippet-code .codepen-extra-css {
  display: none;
}
@media (max-width: 850px) {
  .snippet-group .snippet-demo {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.snippet-group.is-full-width .snippet-demo-container {
  width: 100%;
}
.snippet-group.is-full-width .snippet-demos > .snippet-demo {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.snippet-group.is-full-width .snippet-demo-padding {
  width: 0;
  padding: 8px;
  margin: 0;
}

.docs-layout .docs-text-styling pre[class*="language-markup"] {
  max-width: calc(100vw - 32px);
}
.docs-layout pre[class*="language-markup"] {
  max-width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
}
.docs-layout pre[class*="language-markup"].codepen-button-enabled {
  padding-right: 0;
}
.docs-layout pre[class*="language-markup"].codepen-button-enabled code {
  padding-right: 50px;
}
.codepen-button-enabled .codepen-button {
  display: inline-block;
}
/* Prism and code blocks styling and overrides */
.token.attr-name,
.token.builtin,
.token.selector,
.token.string {
  color: #e91e63;
}
.token.boolean,
.token.constant,
.token.number,
.token.property,
.token.symbol,
.token.tag {
  color: #9d1db3;
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: #00bcd4;
}
.docs-layout code,
.docs-layout pre {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  font-size: 85%;
  white-space: pre-wrap;
}
.docs-layout pre {
  padding: 16px;
  font-size: 87%;
  box-sizing: border-box;
}
.docs-layout code:before,
.docs-layout code:after {
  letter-spacing: -0.2em;
  content: "\00a0";
}
.docs-layout pre > code:before,
.docs-layout pre > code:after {
  letter-spacing: 0;
  content: "";
}
.docs-layout pre > code {
  background-color: rgba(0, 0, 0, 0);
  padding: 0px;
  font-size: 100%;
  width: 100%;
  box-sizing: border-box;
  word-break: break-word;
}
.docs-layout pre[class*="language-"] > code[data-language] {
  overflow: hidden;
}
pre[class*="language-"] > code[data-language]::before {
  content: "";
  background: none;
}
.token.cr:before,
.token.lf:before {
  display: none;
}
.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url,
.token.variable {
  color: #9d1db3;
  background: none;
}
code[class*="language-"],
pre[class*="language-"] {
  color: rgba(0, 0, 0, 0.87);
  text-shadow: none;
}
.token.function {
  color: #009688;
}
.code-with-text {
  position: relative;
  width: auto;
  display: block;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: white;
  padding: 15px;
  border-radius: 3px;
  font-size: 13px;
}
.code-with-text pre {
  margin: 15px -15px -15px -15px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: block;
  width: auto;
}
/* Table of content widget */
.docs-toc ul {
  border-left: solid 3px #c0ebf1;
  padding-left: 20px;
  line-height: 28px;
}
.docs-toc a {
  font-weight: 400;
  color: #00bcd4;
}
.docs-toc li {
  font-size: 16px;
  list-style: none;
}
/* Getting started guide */
.started .mdl-tabs__tab-bar {
  justify-content: flex-start;
  border-bottom: 0;
}
.started .download-button-container {
  text-align: center;
  margin-bottom: 20px;
}
.started .mdl-tabs.is-upgraded .mdl-tabs__tab.is-active {
  color: #00acc1;
}
.started .mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
  background-color: #00acc1;
}
.started .docs-layout-content {
  text-align: center;
}
.started .chapter-toc {
  display: table;
}
.started .code-with-text {
  width: 640px;
  box-sizing: border-box;
}
.started .docs-layout .content section {
  max-width: 960px;
  margin: 0 auto;
}
.started a,
.about a,
.faq a,
.customize a {
  color: #00bcd4;
}
.customize .docs-layout .content {
  max-width: 960px;
  margin: 0 auto;
  display: block;
  margin-bottom: 120px;
  padding: 40px;
}
.started .content h3,
.started .content h4 {
  display: table-cell;
  font-size: 15px;
  padding-right: 60px;
  line-height: 25px;
  text-transform: none;
}
.started .content h3 {
  width: 140px;
}
.started .content section {
  margin-bottom: 30px;
}
.started .section-content {
  display: table-cell;
}
.started .content,
.styles .content,
.faq .content {
  padding: 40px;
  display: inline-block;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}
.docs-layout ul {
  list-style-type: none;
}
.docs-layout li {
  position: relative;
}
.styles .content li:before,
.docs-readme .content li:before {
  position: absolute;
  top: 2px;
  left: -28px;
  content: "•";
  font-size: 32px;
}
.started .content p {
  margin-top: 10px;
  margin-bottom: 15px;
}
.started .mdl-tabs {
  margin-bottom: 22px;
}
.started .content .mdl-tabs__panel {
  padding-top: 10px;
}
.started .caption {
  font-size: 13px;
  max-width: 640px;
  box-sizing: border-box;
  margin-top: 15px;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
}
.started .caption h4 {
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  margin-top: 0;
}
.started .use-components pre {
  margin-top: 0;
}
.started .component-example {
  margin: 30px 0;
}
.started ol {
  padding-left: 18px;
  font-size: 13px;
  max-width: 640px;
  box-sizing: border-box;
}
.started pre {
  width: 640px;
  box-sizing: border-box;
  position: relative;
}
.started .snippet-group {
  max-width: 640px;
  margin: 60px 0 40px 0;
}
@media (max-width: 850px) {
  .started .chapter-toc {
    display: block;
  }
  .started .content h3,
  .started .content h4 {
    display: block;
    width: auto;
  }
  .started .section-content {
    display: block;
  }
  .started pre {
    width: auto;
    margin-left: -40px;
    margin-right: -40px;
    padding: 15px 40px;
    box-sizing: content-box;
    display: block;
    max-width: none;
  }
  .started .caption {
    margin-left: -40px;
    margin-right: -40px;
    max-width: none;
    box-sizing: content-box;
    padding: 15px 40px;
  }
  .started .mdl-tabs__tab-bar {
    margin-left: -40px;
    margin-right: -40px;
    justify-content: center;
  }
  .started .mdl-tabs__tab {
    padding: 0 3%;
  }
  .started .content {
    display: block;
  }
  .started .snippet-group {
    margin-left: -40px;
    margin-right: -40px;
    max-width: none;
  }
  .started .snippet-group code {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*Styles page*/
.styles a {
  color: #00bcd4;
}
.styles .styles__content h2 {
  text-transform: none;
}
.styles .typo-styles {
  margin-bottom: 40px;
}
.styles .typo-styles dt {
  display: block;
  float: left;
  color: white;
  background-color: rgba(0, 0, 0, 0.24);
  width: 32px;
  height: 32px;
  border-radius: 16px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  margin-top: 5px;
}
.styles .typo-styles dd {
  display: block;
  margin-left: 60px;
  margin-bottom: 20px;
}
.styles .typo-styles .typo-styles__demo {
  margin-bottom: 8px;
}
.styles .typo-styles .typo-styles__desc {
  font-weight: 300;
}
.styles .typo-styles .typo-styles__desc .typo-styles__name {
  margin-right: 8px;
  font-weight: 400;
}
.styles .download-btn {
  color: rgba(0, 0, 0, 0.54);
  line-height: 20px;
  display: flex;
  font-weight: 300;
  margin-bottom: 20px;
}
.styles .download-btn.download-btn--customizer .material-icons {
  margin-top: -2px;
}
.styles .download-btn > * {
  margin-right: 8px;
}
.styles .styles__ribbon {
  background-color: #4a148c;
  width: 100vw;
  margin: 80px 0;
  margin-left: -40px;
  height: 320px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}
.styles .styles__ribbon > .ribbon__imagecontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.styles .styles__ribbon > .ribbon__imagecontainer > .ribbon__image {
  display: block;
  margin-bottom: 48px;
  border: 0;
}
.styles .styles__ribbon > .ribbon__imagecontainer > .ribbon__caption {
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  height: 24px;
  line-height: 24px;
  text-align: center;
}
.styles
  .styles__ribbon
  > .ribbon__imagecontainer
  > .ribbon__caption.ribbon__caption--split {
  width: 100%;
  text-align: left;
}
.styles
  .styles__ribbon
  > .ribbon__imagecontainer
  > .ribbon__caption
  > .material-icons {
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  margin-top: -1px;
}
.styles
  .styles__ribbon
  > .ribbon__imagecontainer
  > .ribbon__caption.ribbon__caption--split
  > .material-icons {
  float: right;
}
.styles .content .docs-text-styling h3 {
  text-transform: none;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}
.styles .content .docs-text-styling p {
  margin-top: 0;
}
.styles img.customizer {
  max-width: 450px;
}
.styles .code-with-text {
  margin-right: 40px;
}
.styles
  .docs-layout.is-small-screen
  .code-with-text
  pre[class*="language-markup"] {
  width: 100vw;
  max-width: none;
}
.styles .content ul,
.components .content ul {
  font-size: 13px;
}
.styles .content li:before {
  font-size: 16px;
}
.styles .content .mdl-cell.left-col {
  padding-right: 40px;
}
.styles .content .mdl-cell.right-col {
  margin-bottom: 40px;
}
.styles .code-with-text {
  margin-bottom: 20px;
}
.styles .content .styles__content {
  max-width: 960px;
  margin: 0 auto;
}
.styles .styles__download {
  display: flex;
}
.styles .styles__download a {
  font-weight: 500;
  margin-right: 16px;
}
.styles .styles__content a .customizer {
  border: 0;
}

@media (max-width: 850px) {
  .started .docs-layout .docs-text-styling pre[class*="language-markup"] {
    max-width: none;
  }
  .docs-layout .code-with-text {
    width: 100%;
    margin-left: -40px;
    margin-right: -40px;
    padding: 15px 40px;
    display: block;
    box-sizing: content-box;
  }
  .docs-layout .code-with-text pre {
    width: auto;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2),
  /* Webkit-based browsers */ (min--moz-device-pixel-ratio: 2),
  /* Older Firefox browsers (prior to firefox 16) */ (min-resolution: 2dppx),
  /* The standard way */ (min-resolution: 192dpi) /* dppx fallback */ {
}
.docs-navigation .spacer {
  flex-grow: 1;
}
.components .docs-layout.is-small-screen .docs-text-styling pre {
  margin: 0 -16px;
  width: 100vw;
  max-width: 640px;
}
.docs-text-styling p {
  margin-top: 16px;
  margin-bottom: 16px;
}
.components .content blockquote {
  font-size: 13px;
  max-width: 640px;
  box-sizing: border-box;
  margin-top: 15px;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  margin-left: 0;
}
.components .content blockquote:before,
.components .content blockquote:after {
  display: none;
}
/* .started .caption h4 {
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  margin-top: 0;
}
 */

/* faq */
.faq .docs-text-styling > section {
  max-width: 960px;
  margin: 0 auto;
}
.faq img {
  max-width: 100%;
}
.faq .mdl-tabs__tab-bar {
  justify-content: flex-start;
  border-bottom: 0;
}
.faq .mdl-tabs.is-upgraded .mdl-tabs__tab.is-active {
  color: #00acc1;
}
.faq .mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
  background-color: #00acc1;
}
.faq .docs-layout-content {
  text-align: center;
}
.faq .chapter-toc {
  display: table;
}
.faq .content h3 {
  display: table-cell;
  width: 140px;
  font-size: 15px;
  font-weight: bold;
  padding-right: 60px;
  line-height: 25px;
}
.faq .content section {
  margin-bottom: 30px;
}
.faq .section-content {
  display: table-cell;
}
.faq .content p {
  margin-top: 10px;
  margin-bottom: 15px;
}
.faq .mdl-tabs {
  margin-bottom: 22px;
}
.faq .content h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 20px;
}
.faq .content .mdl-tabs__panel {
  padding-top: 10px;
}
.faq .docs-layout.is-small-screen .docs-toc > * {
  display: block;
}
.faq .caption {
  font-size: 13px;
  max-width: 640px;
  box-sizing: border-box;
  margin-top: 15px;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
}
.faq .caption h4 {
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  margin-top: 0;
}
.faq ol {
  padding-left: 18px;
  font-size: 13px;
  max-width: 640px;
  box-sizing: border-box;
}
.faq pre {
  width: 640px;
  box-sizing: border-box;
  word-wrap: break-word;
}
.faq .snippet-group {
  max-width: 640px;
  margin: 60px 0 40px 0;
}
